import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import ContentGateModal from './Modal';
import * as styles from './contentGate.module.scss';

class ContentGate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gated: props.gated || false,
    };
  }

  onModalRemove = () => {
    this.setState({
      gated: false,
    });
  };

  render() {
    const { children, modalContent } = this.props;
    const { gated } = this.state;
    return (
      <section>
        <div className={`${styles.content} ${gated && styles.gated}`}>{children}</div>
        <ContentGateModal visible={gated} content={modalContent} onRemove={this.onModalRemove} />
      </section>
    );
  }
}

ContentGate.propTypes = {
  gated: PropTypes.bool,
  children: PropTypes.node,
  modalContent: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    description: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ContentGate;
