import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import anime from 'animejs';
import * as styles from './image.module.scss';
import '../../../styles/experimental/author-image.scss';

class Image extends Component {
  componentDidMount() {
    this.primaryAuthorBlob(this.refs.primaryAuthorBlob);
    this.secondaryAuthorBlob(this.refs.secondaryAuthorBlob);
    this.smallAuthorBlob(this.refs.smallAuthorBlob);
  }

  componentDidUpdate() {
    this.primaryAuthorBlob(this.refs.primaryAuthorBlob);
    this.secondaryAuthorBlob(this.refs.secondaryAuthorBlob);
    this.smallAuthorBlob(this.refs.smallAuthorBlob);
  }

  primaryAuthorBlob = el => {
    anime({
      targets: el,
      d: [
        {
          value:
            'M82.383138,21.305013 C74.3870443,14.3857422 35.6748839,-3.92690736 14.4615885,22.8235677 C-6.17465128,48.8463619 6.21552815,78.5100042 20.8823924,87.7650357 C31.2120358,94.2832099 38.853773,88.5425844 46.0768297,86.6706147 C61.5055097,82.6720277 75.5984805,93.9970278 86.3753255,77.8421224 C100.974718,55.9570714 99.6439887,37.1113682 82.383138,21.305013 Z',
        },
        {
          value:
            'M82.383138,21.305013 C50.8149615,-9.26822917 38.208087,1.73064473 16.9947917,28.4811198 C-3.64144816,54.503914 4.52046724,69.2779028 16.9947917,81.5384115 C21.6666667,86.1302083 38.1510045,95.4134115 52.6835937,95.4134115 C57.8404948,95.4134115 75.5984805,93.9970278 86.3753255,77.8421224 C100.974718,55.9570714 99.6439887,37.1113682 82.383138,21.305013 Z',
        },
        {
          value:
            'M82.383138,21.305013 C50.8149615,-9.26822917 30.5559896,7.53450521 16.0481771,23.7851563 C-0.996135049,42.8770193 21.8326823,62.9557292 29.6777344,84.343099 C31.9753723,90.6069748 38.1510045,95.4134115 52.6835937,95.4134115 C57.8404948,95.4134115 75.5984805,93.9970278 86.3753255,77.8421224 C100.974718,55.9570714 99.6439887,37.1113682 82.383138,21.305013 Z',
        },
      ],
      easing: 'easeOutQuad',
      direction: 'alternate',
      duration: 5000,
      loop: true,
    });
  };

  secondaryAuthorBlob = el => {
    anime({
      targets: el,
      d: [
        {
          value:
            'M82.383138,21.305013 C50.8149615,-9.26822917 38.208087,1.73064473 16.9947917,28.4811198 C-3.64144816,54.503914 4.52046724,69.2779028 16.9947917,81.5384115 C21.6666667,86.1302083 38.1510045,95.4134115 52.6835937,95.4134115 C57.8404948,95.4134115 75.5984805,93.9970278 86.3753255,77.8421224 C100.974718,55.9570714 99.6439887,37.1113682 82.383138,21.305013 Z',
        },
        {
          value:
            'M82.383138,21.305013 C74.3870443,14.3857422 35.6748839,-3.92690736 14.4615885,22.8235677 C-6.17465128,48.8463619 6.21552815,78.5100042 20.8823924,87.7650357 C31.2120358,94.2832099 38.853773,88.5425844 46.0768297,86.6706147 C61.5055097,82.6720277 75.5984805,93.9970278 86.3753255,77.8421224 C100.974718,55.9570714 99.6439887,37.1113682 82.383138,21.305013 Z',
        },
        {
          value:
            'M82.3805517,24.4439205 C61.3160183,-3.65434209 33.7026472,-6.77477608 12.4893519,19.975699 C-8.14688793,45.9984932 6.21552815,78.5100042 20.8823924,87.7650357 C31.2120358,94.2832099 38.853773,88.5425844 46.0768297,86.6706147 C61.5055097,82.6720277 66.2699395,98.2338434 79.7955856,84.2441596 C96.7765415,66.680621 97.6381969,46.747208 82.3805517,24.4439205 Z',
        },
      ],
      easing: 'easeOutQuad',
      direction: 'alternate',
      duration: 5000,
      loop: true,
    });
  };

  smallAuthorBlob = el => {
    anime({
      targets: el,
      d: [
        {
          value:
            'M50.2,38.3c0,0,11.8-37.5-20.2-38C11.2,0,1.4,13.7,0.7,23.6c-0.5,7,4.7,11.1,7.6,14.3c6.2,6.7,10.1,10.6,19.1,17.1c8.9,6.5,19.3-7.6,19.3-7.6L50.2,38.3z',
        },
        {
          value:
            'M50.2,38.3c0,0,11.8-37.5-20.2-38C11.2,0,1.4,13.7,0.7,23.6c-0.5,7,3.4,18.6,6.3,21.8c6.2,6.7,11.4,3.1,20.4,9.6c8.9,6.5,19.3-7.6,19.3-7.6L50.2,38.3z',
        },
        {
          value:
            'M50.2,38.3c-6,0.6,1.1-36.4-20.2-38C4.1-0.6-0.6,11.6,0.7,23.6c-0.5,7,4.7,11.1,7.6,14.3c6.2,6.7,10.1,10.6,19.1,17.1c8.9,6.5,19.3-7.6,19.3-7.6L50.2,38.3z',
        },
      ],
      easing: 'easeOutQuad',
      direction: 'alternate',
      duration: 5000,
      loop: true,
    });
  };

  render() {
    const {
      name,
      url,
      slug,
      secondaryName,
      secondaryUrl,
      secondarySlug,
      size,
      archived,
      secondaryArchived,
    } = this.props;

    return (
      <figure
        className={[styles.images, styles[size], secondaryUrl ? styles.doubleImage : ''].join(' ')}>
        {slug === 'emergence' || archived ? (
          <div
            className={[styles.image, 'author-image', 'primary-author-image'].join(' ')}
            style={{ backgroundImage: 'url(' + url + ')' }}
          />
        ) : (
          <div className={[styles.image, 'author-image', 'primary-author-image'].join(' ')}>
            <Link
              aria-label={name}
              style={{ backgroundImage: 'url(' + url + ')' }}
              to={`/people/${slug}`}
            />
          </div>
        )}

        {secondaryUrl &&
          (secondarySlug === 'emergence' || secondaryArchived ? (
            <div
              className={[styles.image, 'author-image', 'secondary-author-image'].join(' ')}
              style={{ backgroundImage: 'url(' + secondaryUrl + ')' }}
            />
          ) : (
            <div className={[styles.image, 'author-image', 'secondary-author-image'].join(' ')}>
              <Link
                aria-label={secondaryName}
                style={{ backgroundImage: 'url(' + secondaryUrl + ')' }}
                to={`/people/${secondarySlug ? secondarySlug : ''}`}
              />
            </div>
          ))}

        {size === 'small' ? (
          <svg className={styles.clippingMask} width="0" height="0">
            <clipPath id="smallAuthorImageMask">
              <path
                ref="smallAuthorBlob"
                d="M50.2,38.3c0,0,11.8-37.5-20.2-38C11.2,0,1.4,13.7,0.7,23.6c-0.5,7,4.7,11.1,7.6,14.3c6.2,6.7,10.1,10.6,19.1,17.1c8.9,6.5,19.3-7.6,19.3-7.6L50.2,38.3z"
              />
            </clipPath>
          </svg>
        ) : (
          <svg className={styles.clippingMask} width="0" height="0">
            <defs>
              <clipPath id="primaryAuthorImageMask">
                <path
                  ref="primaryAuthorBlob"
                  d="M82.3805517,24.4439205 C61.3160183,-3.65434209 33.7026472,-6.77477608 12.4893519,19.975699 C-8.14688793,45.9984932 6.21552815,78.5100042 20.8823924,87.7650357 C31.2120358,94.2832099 38.853773,88.5425844 46.0768297,86.6706147 C61.5055097,82.6720277 66.2699395,98.2338434 79.7955856,84.2441596 C96.7765415,66.680621 97.6381969,46.747208 82.3805517,24.4439205 Z"
                />
              </clipPath>
            </defs>
          </svg>
        )}

        {secondaryUrl && (
          <svg className={styles.clippingMask} width="0" height="0">
            <defs>
              <clipPath id="secondaryAuthorImageMask">
                <path
                  ref="secondaryAuthorBlob"
                  d="M82.383138,21.305013 C50.8149615,-9.26822917 30.5559896,7.53450521 16.0481771,23.7851563 C-0.996135049,42.8770193 21.8326823,62.9557292 29.6777344,84.343099 C31.9753723,90.6069748 38.1510045,95.4134115 52.6835937,95.4134115 C57.8404948,95.4134115 75.5984805,93.9970278 86.3753255,77.8421224 C100.974718,55.9570714 99.6439887,37.1113682 82.383138,21.305013 Z"
                />
              </clipPath>
            </defs>
          </svg>
        )}
      </figure>
    );
  }
}

Image.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  secondaryName: PropTypes.string,
  secondaryUrl: PropTypes.string,
  secondarySlug: PropTypes.string,
  archived: PropTypes.bool,
  secondaryArchived: PropTypes.bool,
};

export default Image;
