// extracted by mini-css-extract-plugin
export var cardSection = "cardSection-module--cardSection--1Sebq";
export var hasImage = "cardSection-module--hasImage--nFgc0";
export var inner = "cardSection-module--inner--2KFL2";
export var cardWrapper = "cardSection-module--cardWrapper--wpsOj";
export var card = "cardSection-module--card--1SEG9";
export var outerLink = "cardSection-module--outerLink--Su_2r";
export var content = "cardSection-module--content--1VNl0";
export var overline = "cardSection-module--overline--3-6o8";
export var title = "cardSection-module--title--mpUZF";
export var description = "cardSection-module--description--3Lcgd";
export var imageWrapper = "cardSection-module--imageWrapper--mibcE";
export var image = "cardSection-module--image--1T4Fk";
export var cta = "cardSection-module--cta--3T4Kg";