import React from 'react';
import * as PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import * as styles from './quoteSection.module.scss';

const QuoteSection = ({ content }) => (
  <div className={styles.quoteSection}>
    <div className={styles.inner}>
      <div className={styles.quoteWrapper}>
        {content.quote && (
          <blockquote
            dangerouslySetInnerHTML={{ __html: content.quote.childMarkdownRemark.html }}
            className={styles.quote}
          />
        )}
      </div>
      {content.attribution && (
        <div className={styles.attributionWrapper}>
          <p className={styles.attribution}>
            <strong>{content.attribution}</strong>
            {content.attributionTitle && (
              <span>
                <br />
                {content.attributionTitle}
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  </div>
);

QuoteSection.propTypes = {
  content: PropTypes.object.isRequired,
};

export default QuoteSection;

export const query = graphql`
  fragment QuoteSectionQuery on ContentfulQuoteSection {
    id
    quote {
      childMarkdownRemark {
        html
      }
    }
    attribution
    attributionTitle
  }
`;
