import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import * as styles from './subscriberSection.module.scss';

const SubscriberSection = ({ content }) => {
  const { headline, subheadline } = content;

  return (
    <div className={styles.subscriberSection}>
      <div className={styles.inner}>
        <div className={styles.cardWrapper}>
          <div className={styles.card}>
            <h2 className={styles.title}>{headline}</h2>
            {subheadline && subheadline.subheadline && <p>{subheadline.subheadline}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriberSection.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.shape({
      subheadline: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SubscriberSection;

export const query = graphql`
  fragment SubscriberSectionQuery on ContentfulSubscriberSection {
    id
    headline
    subheadline {
      subheadline
    }
  }
`;
