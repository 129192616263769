import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import * as styles from './listSection.module.scss';

const ListSection = ({ content }) => {
  const { list, isOrdered } = content;

  const renderList =
    list &&
    list.map((listItem, i) => {
      return (
        <li className={styles.item} key={i}>
          {listItem.title && <h4 className={styles.itemTitle}>{listItem.title}</h4>}
          {listItem.description && (
            <p className={styles.itemDescription}>{listItem.description.description}</p>
          )}
        </li>
      );
    });

  return (
    <div className={styles.listSection}>
      <div className={styles.inner}>
        {isOrdered ? (
          <ol className={[styles.list, styles.orderedList].join(' ')}>{renderList}</ol>
        ) : (
          <ul className={[styles.list, styles.unorderedList].join(' ')}>{renderList}</ul>
        )}
      </div>
    </div>
  );
};

ListSection.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ListSection;

export const query = graphql`
  fragment ListSectionQuery on ContentfulListSection {
    isOrdered
    list {
      title
      description {
        description
      }
    }
  }
`;
