import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import * as styles from './cardSection.module.scss';

const CardSection = ({ content }) => {
  const { title, overline, outerLink, description, image, innerLinkLabel, innerLinkUrl } = content;

  const mainContent = (
    <div className={styles.card}>
      <div className={styles.content}>
        {overline && <h6 className={styles.overline}>{overline}</h6>}
        <h2 className={styles.title}>{title}</h2>
        {description && <p className={styles.description}>{description}</p>}

        {!outerLink && innerLinkLabel && innerLinkUrl && (
          <a className={styles.cta} href={innerLinkUrl} target="_blank" rel="noreferrer">
            {innerLinkLabel}
          </a>
        )}
      </div>
      {image && image.file && (
        <figure className={styles.imageWrapper}>
          <div
            className={styles.image}
            style={{ backgroundImage: 'url(' + image.file.url + ')' }}
          />
        </figure>
      )}
    </div>
  );

  return (
    <div className={[styles.cardSection, image ? styles.hasImage : ''].join(' ')}>
      <div className={styles.inner}>
        <div className={styles.cardWrapper}>
          {outerLink ? (
            <a className={styles.outerLink} href={outerLink} target="_blank" rel="noreferrer">
              {mainContent}
            </a>
          ) : (
            <div>{mainContent}</div>
          )}
        </div>
      </div>
    </div>
  );
};

CardSection.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CardSection;

export const query = graphql`
  fragment CardSectionQuery on ContentfulCardSection {
    title
    overline
    innerLinkLabel
    innerLinkUrl
    outerLink
    description
    image {
      file {
        url
      }
    }
  }
`;
