import React from 'react';
import * as PropTypes from 'prop-types';

import Email from '../../svgs/Email';
import Facebook from '../../svgs/Facebook';
import Link from '../../svgs/Link';
import LinkedIn from '../../svgs/LinkedIn';
import Twitter from '../../svgs/Twitter';

import * as styles from './shareLinks.module.scss';

const ShareLinks = ({ siteData, seoData, defaultTitle, pathName }) => {
  const href = siteData && `${siteData.siteMetadata.siteUrl}${pathName}`;
  const origin = siteData && `${siteData.siteMetadata.siteUrl}`;

  const title = seoData && seoData.customTitle ? seoData.customTitle : defaultTitle;
  const description = seoData && seoData.metaDescription && seoData.metaDescription;

  const twParams = { url: href, via: 'emergencecap', text: title };
  const fbParams = { u: href };
  const mtParams = { subject: title, body: description + href };
  const liParams = { url: href, title: title, summary: description, source: origin };

  return (
    <ul className={styles.shareLinks}>
      <li>
        <a
          aria-label="Share on Facebook"
          className={styles.link}
          href={'https://www.facebook.com/sharer/sharer.php?' + buildQuery(fbParams)}
          target="_blank" rel="noreferrer">
          <Facebook />
        </a>
      </li>
      <li>
        <a
          aria-label="Share on Twitter"
          className={styles.link}
          href={'https://twitter.com/intent/tweet?' + buildQuery(twParams)}
          target="_blank" rel="noreferrer">
          <Twitter />
        </a>
      </li>
      <li>
        <a
          aria-label="Share on LinkedIn"
          className={styles.link}
          href={'http://www.linkedin.com/shareArticle?' + buildQuery(liParams)}
          target="_blank" rel="noreferrer">
          <LinkedIn />
        </a>
      </li>
      <li>
        <a
          aria-label="Share via Email"
          className={styles.link}
          href={'mailto:?' + buildQuery(mtParams)}>
          <Email />
        </a>
      </li>
      <li>
        <div role="link" className={styles.link} onClick={e => copyText(e, href)} onKeyDown={e => copyText(e, href)} tabIndex={0}>
          <Link aria-label="Copy link" />
          <span id="copiedUrl" className={[styles.copiedUrl].join(' ')}>
            URL Copied
          </span>
        </div>
      </li>
    </ul>
  );
};

const copyText = (e, href) => {
  const textField = document.createElement('textarea');
  textField.innerText = href;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  animateCopiedUrl();
};

const animateCopiedUrl = () => {
  const copiedUrl = document.getElementById('copiedUrl');
  copiedUrl.classList.add(styles.animate);
  setTimeout(() => {
    copiedUrl.classList.remove(styles.animate);
  }, 2000);
};

const buildQuery = params => {
  let queryString = [];
  for (let param in params) queryString.push(`${param}=${encodeURIComponent(params[param])}`);
  return queryString.join('&');
};

ShareLinks.propTypes = {
  seoData: PropTypes.object,
  siteData: PropTypes.object.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
};

export default ShareLinks;
