import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import * as styles from './headerSection.module.scss';

class HeaderSection extends Component {
  componentDidMount() {
    this.props.updateTableOfContent(this);
  }
  componentDidUpdate() {
    this.props.updateTableOfContent(this);
  }
  render() {
    return (
      <div className={styles.headerSection}>
        <div className={styles.inner}>
          <h2 className={styles.title} ref="section">
            {this.props.heading}
          </h2>
        </div>
      </div>
    );
  }
}

HeaderSection.propTypes = {
  heading: PropTypes.string.isRequired,
  updateTableOfContent: PropTypes.func.isRequired,
};

export default HeaderSection;
