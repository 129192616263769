// extracted by mini-css-extract-plugin
export var enhancedHeader = "enhancedIntroSection-module--enhancedHeader--3kfDx";
export var inner = "enhancedIntroSection-module--inner--axFN3";
export var content = "enhancedIntroSection-module--content--2kA8P";
export var cardWrapper = "enhancedIntroSection-module--cardWrapper--26wPH";
export var card = "enhancedIntroSection-module--card--2ucvO";
export var tags = "enhancedIntroSection-module--tags--uuL4H";
export var title = "enhancedIntroSection-module--title--377QM";
export var subtitle = "enhancedIntroSection-module--subtitle--1yf6p";
export var shareLinks = "enhancedIntroSection-module--shareLinks--KUVv2";
export var author = "enhancedIntroSection-module--author--1Rtn6";
export var authorInfo = "enhancedIntroSection-module--authorInfo--271q-";
export var overview = "enhancedIntroSection-module--overview--JU49y";
export var abstract = "enhancedIntroSection-module--abstract--nuGuc";
export var toc = "enhancedIntroSection-module--toc--3Ooo6";
export var tocTitle = "enhancedIntroSection-module--tocTitle--1vBiI";
export var tocList = "enhancedIntroSection-module--tocList--1_3dy";
export var tocItem = "enhancedIntroSection-module--tocItem--33vth";
export var tocItemText = "enhancedIntroSection-module--tocItemText--Vjhw3";
export var tocItemArrow = "enhancedIntroSection-module--tocItemArrow--3QnNq";
export var signature = "enhancedIntroSection-module--signature--37yym";