// extracted by mini-css-extract-plugin
export var thoughts = "featuredThoughts-module--thoughts--3ktpp";
export var cement = "featuredThoughts-module--cement--19tbf";
export var content = "featuredThoughts-module--content--MDrjR";
export var title = "featuredThoughts-module--title--2laHK";
export var primaryThought = "featuredThoughts-module--primaryThought--20wmo";
export var secondaryThought = "featuredThoughts-module--secondaryThought--19ZXP";
export var primaryThoughts = "featuredThoughts-module--primaryThoughts--2HgS_";
export var primaryTitle = "featuredThoughts-module--primaryTitle--1rIZj";
export var secondaryTitle = "featuredThoughts-module--secondaryTitle--QQ9RQ";
export var secondaryThoughts = "featuredThoughts-module--secondaryThoughts--3QyG2";
export var authorAndTags = "featuredThoughts-module--authorAndTags--2cNPe";
export var authorImage = "featuredThoughts-module--authorImage--3pnlB";
export var tags = "featuredThoughts-module--tags--RLiFC";