import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import * as styles from './imageSection.module.scss';

const ImageSection = props => {
  const { content } = props;
  const { alignment, title, image } = content;

  return (
    <div
      className={
        alignment
          ? [styles.imageSection, styles[alignment.replace(/\s+/g, '-').toLowerCase()]].join(' ')
          : styles.imageSection
      }>
      <div className={styles.inner}>
        <figure className={styles.figure}>
          {image && image.file && <img className={styles.image} src={image.file.url} alt={title} />}
          {title && <figcaption className={styles.figcaption}>{title}</figcaption>}
        </figure>
      </div>
    </div>
  );
};

ImageSection.propTypes = {
  content: PropTypes.object.isRequired,
};

export default ImageSection;

export const query = graphql`
  fragment ImageSectionQuery on ContentfulImageSection {
    id
    image {
      file {
        url
      }
    }
    title
    alignment
  }
`;
