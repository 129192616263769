import React, { Component } from 'react';
import {includes, sortBy, reduce, filter, uniqWith, isEqual} from 'lodash';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../../components/SEO';
import Theme from '../../components/Theme';
import IntroSection from '../../components/ThoughtSections/IntroSection';
import EnhancedIntroSection from '../../components/ThoughtSections/EnhancedIntroSection';
import TextSection from '../../components/ThoughtSections/TextSection';
import ImageSection from '../../components/ThoughtSections/ImageSection';
import VideoSection from '../../components/ThoughtSections/VideoSection';
import QuoteSection from '../../components/ThoughtSections/QuoteSection';
import CardSection from '../../components/ThoughtSections/CardSection';
import ListSection from '../../components/ThoughtSections/ListSection';
import HeaderSection from '../../components/ThoughtSections/HeaderSection';
import SubcriberSection from '../../components/ThoughtSections/SubscriberSection';
import FeaturedThoughts from '../../components/FeaturedThoughts';
import Newsletter from '../../components/Newsletter';
import ContentGate from '../../components/ContentGate';
import GeneralLayout from '../../layouts/index';

class ThoughtTemplate extends Component {
  state = {
    tableOfContents: [],
  };

  renderSection = (section, i) => {
    switch (section.__typename) {
      case 'ContentfulHeaderSection':
        return (
          <HeaderSection
            key={i}
            index={i}
            heading={section.heading}
            updateTableOfContent={this.updateTableOfContent}
          />
        );
      case 'ContentfulTextSection':
        return <TextSection key={i} content={section} />;
      case 'ContentfulImageSection':
        return <ImageSection key={i} content={section} />;
      case 'ContentfulVideoSection':
        return <VideoSection key={i} content={section} />;
      case 'ContentfulQuoteSection':
        return <QuoteSection key={i} content={section} />;
      case 'ContentfulCardSection':
        return <CardSection key={i} content={section} />;
      case 'ContentfulListSection':
        return <ListSection key={i} content={section} />;
      case 'ContentfulSubscriberSection':
        return <SubcriberSection key={i} content={section} />;
      default:
        return;
    }
  };

  updateTableOfContent = el => {
    if (!includes(this.state.tableOfContents, el)) {
      this.setState({
        tableOfContents: sortBy([...this.state.tableOfContents, el], [el => el.props.index]),
      });
    }
  };

  buildRelatedThoughts = thought => {
    const relatedThoughts = reduce(
      thought.tags,
      (acc, val) => {
        return acc.concat(val.thought);
      },
      [],
    );

    const filteredThoughts = filter(relatedThoughts, t => {
      return t && t.title !== thought.title;
    });

    return uniqWith(filteredThoughts, isEqual).sort((a, b) => {
      const dateA = new Date(a.publishedDate);
      const dateB = new Date(b.publishedDate);

      return dateB - dateA;
    });
  };

  render() {
    const { transition, data } = this.props;
    const { contentfulThought, contentfulContentGateModal, contentfulNewsletter, site } = data;
    const { content: contentSections, enhancedHeader, isContentGated } = contentfulThought;
    const relatedThoughts = this.buildRelatedThoughts(contentfulThought);

    return (
        <GeneralLayout>
          <Theme transition={transition}>
            <Seo
              siteData={site}
              seoData={contentfulThought.seo}
              defaultTitle={contentfulThought.title}
              pathName={`/thoughts/${contentfulThought.slug}`}
              type="article"
            />

            {enhancedHeader ? (
              <EnhancedIntroSection
                thought={contentfulThought}
                tableOfContents={this.state.tableOfContents}
                siteData={site}
                seoData={contentfulThought.seo}
                defaultTitle={contentfulThought.title}
                pathName={this.props.location.pathname}
              />
            ) : (
              <IntroSection
                thought={contentfulThought}
                siteData={site}
                seoData={contentfulThought.seo}
                defaultTitle={contentfulThought.title}
                pathName={this.props.location.pathname}
              />
            )}
            <ContentGate gated={isContentGated} modalContent={contentfulContentGateModal}>
              {contentSections &&
                contentSections.map((section, i) => {
                  return this.renderSection(section, i);
                })}
            </ContentGate>
            {relatedThoughts.length > 0 && (
              <FeaturedThoughts thoughts={relatedThoughts} headline="Related Thoughts" theme="cement" />
            )}
            <Newsletter content={contentfulNewsletter} />
          </Theme>
        </GeneralLayout>
    );
  }
}

ThoughtTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ThoughtTemplate;

export const pageQuery = graphql`
  query thoughtQuery($id: String!) {
    contentfulThought(id: { eq: $id }) {
      slug
      enhancedHeader
      ...EnhancedIntroSectionQuery
      ...IntroSectionQuery
      content {
        ... on ContentfulHeaderSection {
          heading
        }
        __typename
        ... on ContentfulTextSection {
          ...TextSectionQuery
        }
        ... on ContentfulImageSection {
          ...ImageSectionQuery
        }
        ... on ContentfulVideoSection {
          ...VideoSectionQuery
        }
        ... on ContentfulQuoteSection {
          ...QuoteSectionQuery
        }
        ... on ContentfulCardSection {
          ...CardSectionQuery
        }
        ... on ContentfulListSection {
          ...ListSectionQuery
        }
        ... on ContentfulSubscriberSection {
          ...SubscriberSectionQuery
        }
      }
      isContentGated
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...NewsletterQuery
    ...ContentGateModalQuery
    ...SiteQuery
  }
`;
