import React from 'react';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentfulText from '../ContentfulText';

const TextSection = props => {
  const { content } = props;
  const { text, dropcap } = content;

  return (
    <ContentfulText
      textObj={text}
      className="contentfulThoughtText"
      innerClassName="thoughtInner"
      dropcap={dropcap}
    />
  );
};

TextSection.propTypes = {
  content: PropTypes.object.isRequired,
};

export default TextSection;

export const query = graphql`
  fragment TextSectionQuery on ContentfulTextSection {
    id
    text {
      childMarkdownRemark {
        html
      }
    }
    dropcap
  }
`;
