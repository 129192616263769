import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import * as styles from './tags.module.scss';

const Tags = ({ tags, styleMeta }) => (
  <ul className={[styles.tags, styleMeta ? styles.meta : ''].join(' ')}>
    {tags &&
      tags.map((t, i) => (
        <li key={i} className={styles.tag}>
          <Link
            className={styles.link}
            to={t.__typename ? `${getRelatedLink(t)}/${t.slug}` : `/${t.slug}`}>
            {t.name}
          </Link>
        </li>
      ))}
  </ul>
);

function getRelatedLink(relatedContent) {
  switch (relatedContent.__typename) {
    case 'ContentfulCompany':
      return '/portfolio';
    case 'ContentfulPerson':
      return '/people';
    default:
      return '';
  }
}

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
  styleMeta: PropTypes.bool,
};

export default Tags;
