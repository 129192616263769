import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import * as PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import * as styles from './videoSection.module.scss';

class VideoSection extends Component {
  state = {
    clicked: false,
  };

  removeCover = evt => {
    evt.preventDefault();
    this.setState({ clicked: true });
  };

  render() {
    const { youtubeUrl, coverImage } = this.props.content;

    const videoCover = (
      <div
        role="button"
        onClick={this.removeCover}
        onKeyDown={this.removeCover}
        className={styles.videoCover}
        aria-label="video cover"
        tabIndex={0}
        style={{
          backgroundImage: `url(${coverImage && coverImage.file ? coverImage.file.url : ''})`,
        }}
      />
    );

    return (
      <div className={styles.videoSection}>
        <div className={styles.inner}>
          <div className={styles.videoWrapper}>
            {coverImage && !this.state.clicked && videoCover}
            {youtubeUrl && (
              <ReactPlayer
                className={styles.video}
                url={youtubeUrl}
                playing={this.state.clicked}
                controls
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

VideoSection.propTypes = {
  content: PropTypes.object.isRequired,
};

export default VideoSection;

export const query = graphql`
  fragment VideoSectionQuery on ContentfulVideoSection {
    id
    youtubeUrl
    coverImage {
      file {
        url
      }
    }
  }
`;
