import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const SubstackSubscriberForm = () => {
  useEffect(() => {
    const scriptStyles = document.createElement('script');
    scriptStyles.innerHTML = `window.CustomSubstackWidget = {
      substackUrl: 'emcap.substack.com',
      placeholder: 'Email address',
      buttonText: 'SUBSCRIBE',
      theme: 'custom',
      colors: {
        primary: '#2D4862',
        input: '#324F6B',
        email: '#FFFFFF',
        text: '#FFFFFF',
      },
    }`;
    document.body.appendChild(scriptStyles);

    const scriptUrl = document.createElement('script');
    scriptUrl.src = 'https://substackapi.com/widget.js';
    scriptUrl.async = true;
    document.body.appendChild(scriptUrl);
  }, []);

  return (
    <>
      <Helmet>
        <script>
          {`
              (window.CustomSubstackWidget = {
                substackUrl: "emcap.substack.com",
                placeholder: "Email address",
                buttonText: "SUBSCRIBE",
                theme: "custom",
                colors: {
                  primary: "#2D4862",
                  input: "#324F6B",
                  email: "#FFFFFF",
                  text: "#FFFFFF",
                },
              })
            `}
        </script>
        <script src="https://substackapi.com/widget.js" async></script>
      </Helmet>
      <div id="custom-substack-embed"></div>
    </>
  );
};

export default SubstackSubscriberForm;
