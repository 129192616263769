import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import GateClose from '../../../svgs/GateClose';
import { graphql } from 'gatsby';
import * as styles from './modal.module.scss';

const CONTENT_GATE_VALIDATED = 'contentGateValidated';

class ContentGateModal extends Component {
  constructor(props) {
    super(props);
    const validatedCookie = Cookies.get(CONTENT_GATE_VALIDATED);

    this.state = {
      validated: validatedCookie ? JSON.parse(validatedCookie) : false,
    };

    if (this.state.validated) {
      this.props.onRemove();
    }
  }

  closeModal = () => {
    this.removeGate({
      expires: 3,
    });
  };

  removeGate = options => {
    Cookies.set(CONTENT_GATE_VALIDATED, true, options || {});
    this.setState(
      {
        validated: true,
      },
      this.props.onRemove,
    );
  };

  scrollToNewsletter = () => {
    const newsletter = document.getElementById('newsletter');
    if (newsletter) {
      newsletter.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const { visible } = this.props;
    const { headline, description } = this.props.content;
    const { validated } = this.state;

    return (
      <div className={`${styles.modal} ${!validated && visible && styles.visible}`}>
        <div className={styles.inner}>
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              <h2 className={styles.title}>{headline}</h2>
              {description && description.description && (
                <p className={styles.description}>{description.description}</p>
              )}
              <div>
                <button onClick={this.scrollToNewsletter} className={styles.scrollButton}>
                  Join our newsletter
                </button>
              </div>
              <button className={styles.close} onClick={this.closeModal}>
                <GateClose />
                <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContentGateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  content: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    description: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onRemove: PropTypes.func,
};

export default ContentGateModal;

export const query = graphql`
  fragment ContentGateModalQuery on Query {
    contentfulContentGateModal {
      headline
      description {
        description
      }
    }
  }
`;
