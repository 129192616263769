import React from 'react';
import * as PropTypes from 'prop-types';
import * as styles from './info.module.scss';

const Info = ({ name, title, inline }) => (
  <div className={[styles.info, inline && styles.inline].join(' ')}>
    <span className={[styles.name, title && styles.hasTitle].join(' ')}>{name}</span>
    <span>{title}</span>
  </div>
);

Info.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  inline: PropTypes.bool,
};

export default Info;
