import React from 'react';
import * as styles from './gateClose.module.scss';

const GateClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    className={styles.gateClose}>
    <g strokeWidth="2" transform="translate(1 1)">
      <path d="M0 0L20 20M0 20L20 0" />
    </g>
  </svg>
);

export default GateClose;
