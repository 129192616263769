import React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import AuthorImage from '../../Author/Image';
import AuthorInfo from '../../Author/Info';
import ShareLinks from '../../ShareLinks';
import Tags from '../../Tags';

import * as styles from './introSection.module.scss';

const IntroSection = ({ thought, siteData, seoData, defaultTitle, pathName }) => {
  const { title, author, secondaryAuthor, tags, publishedDate } = thought;

  const authorsWithoutEmergence = author && (
    <div>
      {(author && author.slug === 'emergence') || author.archived ? (
        <AuthorInfo
          name={author.name}
          title={author.title}
          inline={secondaryAuthor ? true : false}
        />
      ) : (
        <Link to={`/people/${author.slug}`}>
          <AuthorInfo
            name={author.name}
            title={author.title}
            inline={secondaryAuthor ? true : false}
          />
        </Link>
      )}
      {secondaryAuthor &&
        (secondaryAuthor.slug === 'emergence' || secondaryAuthor.archived ? (
          <AuthorInfo name={secondaryAuthor.name} title={secondaryAuthor.title} inline={true} />
        ) : (
          <Link to={`/people/${secondaryAuthor.slug}`}>
            <AuthorInfo name={secondaryAuthor.name} title={secondaryAuthor.title} inline={true} />
          </Link>
        ))}
    </div>
  );

  return (
    <header className={styles.introSection}>
      <div className={styles.inner}>
        <div className={styles.content}>
          {tags && (
            <div className={styles.tags}>
              <Tags tags={tags} />
            </div>
          )}
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
          <div className={styles.shareLinks}>
            <ShareLinks
              siteData={siteData}
              seoData={seoData}
              defaultTitle={defaultTitle}
              pathName={pathName}
            />
          </div>
          <div className={styles.meta}>
            <dl className={'authorInfo'}>
              <dt>{authorsWithoutEmergence}</dt>
              <dd>{publishedDate && publishedDate}</dd>
            </dl>
          </div>
        </div>

        {author && author.portrait && (
          <figure className={styles.authorImage}>
            <AuthorImage
              name={author.name}
              url={author.portrait.fluid.src}
              slug={author.slug}
              secondaryName={secondaryAuthor ? secondaryAuthor.name : ''}
              secondaryUrl={secondaryAuthor ? secondaryAuthor.portrait.fluid.src : ''}
              secondarySlug={secondaryAuthor ? secondaryAuthor.slug : ''}
              archived={author.archived}
              secondaryArchived={secondaryAuthor && secondaryAuthor.archived}
            />
          </figure>
        )}
      </div>
    </header>
  );
};

IntroSection.propTypes = {
  thought: PropTypes.object.isRequired,
  seoData: PropTypes.object,
  siteData: PropTypes.object.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
};

export default IntroSection;

export const IntroSectionQuery = graphql`
  fragment IntroSectionQuery on ContentfulThought {
    title
    subtitle
    publishedDate(formatString: "MM/DD/YY")
    author {
      name
      title
      slug
      portrait {
        fluid {
          src
        }
      }
      archived
    }
    secondaryAuthor {
      name
      title
      slug
      portrait {
        fluid {
          src
        }
      }
      archived
    }
    tags {
      ... on ContentfulTag {
        name
        slug
        thought {
          ...TagThoughtsQuery
        }
      }
      ... on ContentfulThesis {
        name
        slug
        thought {
          ...ThesisThoughtsQuery
        }
      }
    }
  }
`;
