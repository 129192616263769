import React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import AuthorImage from '../Author/Image';
import AuthorInfo from '../Author/Info';
import Tags from '../Tags';
import * as styles from './featuredThoughts.module.scss';

const FeaturedThoughts = ({ thoughts, headline, theme }) => {
  const authorsWithoutEmergence = thought => (
    <div>
      {thought.author && (thought.author.slug === 'emergence' || thought.author.archived) ? (
        <AuthorInfo name={thought.author.name} title={thought.author.title} inline={true} />
      ) : (
        <Link to={`/people/${thought.author.slug}`}>
          <AuthorInfo name={thought.author.name} title={thought.author.title} inline={true} />
        </Link>
      )}
      {thought.secondaryAuthor &&
        (thought.secondaryAuthor.slug === 'emergence' || thought.secondaryAuthor.archived ? (
          <AuthorInfo
            name={thought.secondaryAuthor.name}
            title={thought.secondaryAuthor.title}
            inline={true}
          />
        ) : (
          <Link to={`/people/${thought.secondaryAuthor.slug}`}>
            <AuthorInfo
              name={thought.secondaryAuthor.name}
              title={thought.secondaryAuthor.title}
              inline={true}
            />
          </Link>
        ))}
    </div>
  );

  return (
    <section className={[styles.thoughts, styles[theme]].join(' ')}>
      <div className={styles.content}>
        <h4 className={styles.title}>{headline}</h4>
        <div className={styles.primaryThoughts}>
          {thoughts &&
            thoughts.slice(0, 1).map((thought, index) => {
              return (
                <article key={index} className={styles.primaryThought}>
                  <Link to={`/thoughts/${thought.slug}`}>
                    <h2 className={styles.primaryTitle}>{thought.title}</h2>
                  </Link>

                  <div className={styles.authorAndTags}>
                    {thought.author.portrait && (
                      <div className={styles.authorImage}>
                        <AuthorImage
                          name={thought.author.name}
                          url={thought.author.portrait.fluid.src}
                          slug={thought.author.slug}
                          secondaryName={
                            thought.secondaryAuthor ? thought.secondaryAuthor.name : ''
                          }
                          secondaryUrl={
                            thought.secondaryAuthor && thought.secondaryAuthor.portrait
                              ? thought.secondaryAuthor.portrait.fluid.src
                              : ''
                          }
                          secondarySlug={
                            thought.secondaryAuthor ? thought.secondaryAuthor.slug : ''
                          }
                          archived={thought.author.archived}
                          secondaryArchived={
                            thought.secondaryAuthor && thought.secondaryAuthor.archived
                          }
                        />
                      </div>
                    )}

                    <div>
                      {authorsWithoutEmergence(thought)}

                      {thought.tags && (
                        <div className={styles.tags}>
                          <Tags tags={thought.tags} styleMeta={true} />
                        </div>
                      )}
                    </div>
                  </div>
                </article>
              );
            })}
        </div>
        <div className={styles.secondaryThoughts}>
          {thoughts &&
            thoughts.slice(1, 3).map((thought, i) => {
              return (
                <article key={i} className={styles.secondaryThought}>
                  <Link to={`/thoughts/${thought.slug}`}>
                    <h3 className={styles.secondaryTitle}>{thought.title}</h3>
                  </Link>

                  {authorsWithoutEmergence(thought)}

                  {thought.tags && (
                    <div className={styles.tags}>
                      <Tags tags={thought.tags} styleMeta={true} />
                    </div>
                  )}
                </article>
              );
            })}
        </div>
      </div>
    </section>
  );
};

FeaturedThoughts.propTypes = {
  thoughts: PropTypes.array,
  headline: PropTypes.string,
  theme: PropTypes.string,
};

export default FeaturedThoughts;

export const homeQuery = graphql`
  fragment HomepageFeaturedThoughtsQuery on ContentfulHomePage {
    featuredThoughts {
      title
      slug
      author {
        name
        title
        slug
        portrait {
          fluid {
            src
          }
        }
        archived
      }
      secondaryAuthor {
        name
        title
        slug
        portrait {
          fluid {
            src
          }
        }
        archived
      }
      tags {
        ... on ContentfulTag {
          name
          slug
        }
        ... on ContentfulThesis {
          name
          slug
        }
      }
    }
  }
`;

export const thoughtQuery = graphql`
  fragment TagThoughtsQuery on ContentfulThought {
    title
    slug
    author {
      name
      title
      slug
      portrait {
        fluid {
          src
        }
      }
      archived
    }
    secondaryAuthor {
      name
      title
      slug
      portrait {
        fluid {
          src
        }
      }
      archived
    }
    publishedDate(formatString: "MM.DD.YYYY")
    tags {
      ... on ContentfulTag {
        name
        slug
      }
      ... on ContentfulThesis {
        name
        slug
      }
    }
  }

  fragment ThesisThoughtsQuery on ContentfulThought {
    title
    slug
    author {
      name
      title
      slug
      portrait {
        fluid {
          src
        }
      }
      archived
    }
    secondaryAuthor {
      name
      title
      slug
      portrait {
        fluid {
          src
        }
      }
      archived
    }
    publishedDate(formatString: "MM.DD.YYYY")
    tags {
      ... on ContentfulTag {
        name
        slug
      }
      ... on ContentfulThesis {
        name
        slug
      }
    }
  }
`;
