import React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import AuthorImage from '../../Author/Image';
import AuthorInfo from '../../Author/Info';
import ShareLinks from '../../ShareLinks';
import Tags from '../../Tags';
import ContentfulText from '../../ContentfulText';

import * as styles from './enhancedIntroSection.module.scss';

const EnhancedIntroSection = ({
  thought,
  tableOfContents,
  siteData,
  seoData,
  defaultTitle,
  pathName,
}) => {
  const { title, subtitle, abstract, author, secondaryAuthor, tags, showSignature } = thought;

  const authorsWithoutEmergence = (
    <div className={styles.authorInfo}>
      {author &&
        (author.slug === 'emergence' || author.archived ? (
          <AuthorInfo
            name={author.name}
            title={author.title}
            inline={secondaryAuthor ? true : false}
          />
        ) : (
          <Link to={`/people/${author.slug}`}>
            <AuthorInfo
              name={author.name}
              title={author.title}
              inline={secondaryAuthor ? true : false}
            />
          </Link>
        ))}
      {secondaryAuthor &&
        (secondaryAuthor.slug === 'emergence' || secondaryAuthor.archived ? (
          <AuthorInfo name={secondaryAuthor.name} title={secondaryAuthor.title} inline={true} />
        ) : (
          <Link to={`/people/${secondaryAuthor.slug}`}>
            <AuthorInfo name={secondaryAuthor.name} title={secondaryAuthor.title} inline={true} />
          </Link>
        ))}
    </div>
  );

  return (
    <header className={styles.enhancedHeader}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              {tags && (
                <div className={styles.tags}>
                  <Tags tags={tags} />
                </div>
              )}
              <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
              {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
            </div>
            <div className={styles.shareLinks}>
              <ShareLinks
                siteData={siteData}
                seoData={seoData}
                defaultTitle={defaultTitle}
                pathName={pathName}
              />
            </div>
          </div>
          <div className={styles.author}>
            {author.portrait && (
              <AuthorImage
                name={author.name}
                url={author.portrait.fluid.src}
                slug={author.slug}
                secondaryName={secondaryAuthor ? secondaryAuthor.name : ''}
                secondaryUrl={
                  secondaryAuthor && secondaryAuthor.portrait
                    ? secondaryAuthor.portrait.fluid.src
                    : ''
                }
                secondarySlug={secondaryAuthor ? secondaryAuthor.slug : ''}
                archived={author.archived}
                secondaryArchived={secondaryAuthor && secondaryAuthor.archived}
              />
            )}

            {authorsWithoutEmergence}
            {author &&
              author.signature &&
              author.signature.file &&
              author.signature.file.url &&
              false !== showSignature && (
                <img
                  className={styles.signature}
                  src={author.signature.file.url}
                  alt={'Signature of ' + author.name}
                />
              )}
          </div>
          <div className={styles.overview}>
            <div className={styles.abstract}>
              {abstract && <ContentfulText textObj={abstract} />}
            </div>
            {tableOfContents && tableOfContents.length > 0 && (
              <div className={styles.toc}>
                <h6 className={styles.tocTitle}>Sections</h6>
                <ol className={styles.tocList}>
                  {tableOfContents.map((item, i) => {
                    return (
                      <li className={styles.tocItem} key={i}>
                        <a
                          href="#scroll-to-ref-section"
                          onClick={() => scrollTo(item.refs.section)}
                          onKeyDown={() => scrollTo(item.refs.section)}>
                          <span className={styles.tocItemText} title={item.props.heading}>
                            {item.props.heading}
                          </span>
                          <span className={styles.tocItemArrow}>↓</span>
                        </a>
                      </li>
                    );
                  })}
                </ol>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

function scrollTo(el) {
  const boundingRect = el.getBoundingClientRect();
  const BUFFER = 30;

  boundingRect && 'scrollBehavior' in document.documentElement.style
    ? window.scrollTo({ top: boundingRect.top + window.scrollY - BUFFER, behavior: 'smooth' })
    : window.scrollTo(0, boundingRect.top + window.scrollY - BUFFER);
}

EnhancedIntroSection.propTypes = {
  thought: PropTypes.object.isRequired,
  tableOfContents: PropTypes.array,
  seoData: PropTypes.object,
  siteData: PropTypes.object.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
};

export default EnhancedIntroSection;

export const EnhancedIntroSectionQuery = graphql`
  fragment EnhancedIntroSectionQuery on ContentfulThought {
    abstract {
      childMarkdownRemark {
        html
      }
    }
    author {
      signature {
        file {
          url
        }
      }
    }
    showSignature
    ...IntroSectionQuery
  }
`;
